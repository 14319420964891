
import './OurBlogsDetails.css';
import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import {useParams} from 'react-router-dom'
import axios from "axios";
import url from "../url.jsx";
import sweetHK from '../../../src/assets/sweet-hk.png';
import camel from '../../../src/assets/camel-hk.png';
import fbBlack from '../../../src/assets/fbBlack.svg';
import tweetBlack from '../../../src/assets/tweeterBlack.svg';
import linkedInBlack from '../../../src/assets/linkedInBlack.svg';
import fb from '../../../src/assets/fbGreen.png';
import tweetGreen from '../../../src/assets/tweetGreen.png';
import linkedInGreen from '../../../src/assets/linkedGreen.png'
import linkBlack from '../../../src/assets/linkBlack.svg';
import link from '../../../src/assets/linkGreen.svg';
import { useInView } from 'react-intersection-observer';

function OurBlogsDetais() {
  const params=useParams();
  const { slugAndId } = useParams();
  const lastHyphenIndex = slugAndId.lastIndexOf('-');
  const productSlug = slugAndId.substring(0, lastHyphenIndex);
  const productId = slugAndId.substring(lastHyphenIndex + 1);
  const[slug,setSlug]=useState("");
  const [title, setTitle] = useState('');
  const [by, setBy] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);
  
  async function getDetails() {
    const response = await axios.get(`${url.baseURL}/blog/${productId}`);
    setTitle(response.data.blog.title);
    setSlug(response.data.blog.slug)
    setBy(response.data.blog.by)
    setDate(response.data.blog.date)
    setDescription(response.data.blog.description);
    setImages(response.data.blog.blog_images);
    console.log(response.data.blog.blog_images);
  }
  useEffect(() => { 
    getDetails();
  }, []);
  const baseLinkedInURL = 'https://www.linkedin.com/sharing/share-offsite/';
  const baseFacebookURL = 'https://www.facebook.com/sharer/sharer.php';
  const baseTwitterURL = 'https://twitter.com/intent/tweet';
  const encodedParamsId = encodeURIComponent(slugAndId);
  const encodedWebpageURL = `https://www.thegoproject.me/api/blog/${encodedParamsId}`;
  const encodedWebpageURLCopied = `https://www.thegoproject.me/blogs-details/${encodedParamsId}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(encodedWebpageURL)}`;
  const linkedInSharingURL = `${baseLinkedInURL}?url=${encodedWebpageURL}`;
  const FacebookSharingURL = `${baseFacebookURL}?url=${encodedWebpageURL}`;
  const TwitterSharingURL = `${baseTwitterURL}?url=${encodedWebpageURL}`;

  const copyLink = () => {
    navigator.clipboard.writeText(encodedWebpageURLCopied)
      .then(() => {
        console.log('Link copied to clipboard');
alert('Link copied to clipboard')
      })
      .catch((error) => {
        console.error('Failed to copy link', error);
      });
  };
  const [videoRef, inView] = useInView({
    threshold: 0.5, // Trigger the callback when the video is 50% visible
    triggerOnce: true // Only trigger the callback once
  });
  
  return (
    <div className="container">
      <div className="row my-5">
        <div className="col-lg-6 col-md-12">
          <div className="item-bd">
           <div className='blogger-details'>
             <h4 className="blog-title-bd">{title}</h4>
             <p className="blogger-name">By: {by}</p>
             <p className="blogger-date">{date}</p>
           </div><br />
           <div dangerouslySetInnerHTML={{ __html:description}} />

            <div className='share-it mt-5 share-it-mobile'>
                <h4>SHARE IT!</h4>
            </div>


                <div className="social-media d-flex mb-5">
                  <a href={facebookShareUrl} target='_blank' rel="noopener noreferrer">
                    <div className='social-container d-flex'>
                      <img src={fbBlack} alt="facebook" className='social-grid me-2' />
                      <img src={fb} alt="" className='social-hover me-2' />
                    </div>
                  </a>
                  <a href={TwitterSharingURL} target='_blank' rel="noopener noreferrer">
                    <div className='social-container d-flex'>
                      <img src={tweetBlack} alt="tweeter" className='social-grid me-2'/>
                      <img src={tweetGreen} alt="tweet" className='social-hover me-2' />
                    </div>
                  </a>
                  <a href={linkedInSharingURL} target="_blank" rel="noopener noreferrer">
                    <div className='social-container d-flex'>
                      <img src={linkedInBlack} alt="linked in" className='social-grid me-2'/>
                      <img src={linkedInGreen} alt="linkedin" className='social-hover me-2' />
                    </div>
                  </a>
                  <a  href="#" onClick={copyLink}>
                    <div className='social-container d-flex'>
                      <img src={linkBlack} alt="linked in" className='social-grid me-2'/>
                      <img src={link} alt="linkedin" className='social-hover me-2' />
                    </div>
                  </a>
                </div>     
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="item-bd">
          {
            images.map((item) => {
            return [
        
                item.type === "image"
                 ? (
             
                <img  
                  src={`${url.mediaURL}/${item.image}`}
                  alt=""
                  className="img-fluid" 
                  title=""
                />
         
              ) : (
                <div>
  
       
  
  
  <video  controls ref={videoRef} muted loop autoPlay   onPause={() => console.log("Video paused")}
  onPlay={() => console.log("Video playing")}
  style={{ opacity: inView ? 1 : 1 }}>
  <source src={`${url.mediaURL}/${item.image}`} type="video/mp4" />
  </video>
  
  
      </div>
              
              )
      
  
            ]})}
      

          </div>
        </div>
      </div>
    </div>
  );
}

export default OurBlogsDetais;





