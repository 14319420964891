import React, {useState , useEffect} from 'react';
import './JobDetails.css';
import { Link } from 'react-router-dom';
import {useParams} from 'react-router-dom'
import axios from "axios";
import url from "../url.jsx";
function JobDetails() {
  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } }); 
  const params=useParams();
  const { slugAndId } = useParams();
  const lastHyphenIndex = slugAndId.lastIndexOf('-');
  const productSlug = slugAndId.substring(0, lastHyphenIndex);
  const productId = slugAndId.substring(lastHyphenIndex + 1);
  const [allposition , setAllPosition] = useState([]);
  const [office , setOffice] = useState("");
  const [position, setPosition] = useState("");
  const[slug,setSlug]=useState("");
  const[description,setDescription]=useState("");
  const[descriptionul,setDescriptionUl]=useState("");
  const[datexpired,setDatexpired]=useState("");
  async function getposition() {
    const response = await axios.get(`${url.baseURL}/position/${productId}`);
    setAllPosition(response.data.joblist);
    setSlug(response.data.joblist.slug);
    setPosition(response.data.joblist.position);
    setOffice(response.data.joblist.office);
    setDescription(response.data.joblist.description);
    setDescriptionUl(response.data.joblist.descriptionul);
    setDatexpired(response.data.joblist.expiration_date);
    console.log(response.data.joblist);
  }


  const handleLinkClick = (event) => {
    event.preventDefault();
    window.location.href = event.target.href;
  };

  

useEffect(() => {
  console.clear();
  getposition();
}, [])
  return (

    <div>
    <div className='job-detail-wrapper pb-5'>
    <div className="container title-job-desc">
    <div className='row pt-5 d-flex title-job-desc'>
      <div className='col-md-4 jd-column-one'>
        <div>
          <ol class="breadcrumb">
            <li><Link  onClick={handleLinkClick} to="/job-list" className="breadc-jobs">Open Vacancies</Link></li>
            <li><Link  className="active breadc-jobs">{slug}</Link></li>
          </ol>
        </div>  
        <div className="job-titles">
            <h5 className='text-white mt-2'>{position}</h5>
            <p className='text-white mb-2'>{office}</p>
            <p className='text-white'>Date Expired: {datexpired}</p>
        </div>
      </div>
      <div className="col-md-8 jd-column-two text-white">
          <p class="position-details-p mt-md-5">
          
          <div  dangerouslySetInnerHTML={{ __html:description}} />
          <div class="position-ul mt-4">
<ul className='list-style-dots mx-3'>
<li>{renderHTML(descriptionul)}</li>
</ul>
          </div>
          </p>
      </div>
    </div>
    </div>
    </div>


    <section className="infinite-text-about">
    <div className="marquee">
      <div className="track">
        <div className="data-driven-infinite text-uppercase">&nbsp;Success is best when it’s shared. Success is best when it’s shared. Success is best when it’s shared. Success is best when it’s shared. Success is best when it’s shared.</div>
      </div>
    </div>
  </section>
  </div>
  )
}

export default JobDetails