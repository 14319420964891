import React from 'react';
import './NotFoundPage.css';
import notFoundCat from '../../../src/assets/otta.png';
import { Link } from "react-router-dom";
 

function NotFoundPage() {
  const handleLinkClick = (event) => {
    event.preventDefault();
    window.location.href = event.target.href;
  };
  
  return (
    <div className='notFoundBg'>
        <div className="notFoundCat d-flex justify-content-center align-items-center">
            <img src={notFoundCat} alt="" className="notFound-cat mt-5 img-fluid" />
        </div>
        <div>
          <p className='h1 text-white d-flex justify-content-center align-items-center lh'>
              We have a problem here!
          </p>
          <div className='m-5'></div>
   
            <button className='gg mb-3'>
            <a class="text-white" onClick={handleLinkClick} href="/">Back to Home
            </a></button>
    
        </div>
    </div>
  )
}

export default NotFoundPage