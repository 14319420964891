import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import { useParams } from "react-router-dom";
import axios from "axios";
import url from "./url.jsx";
import playBtn from "../../src/assets/PlayV.png";
import GoToTop from "./GoToTop";
import { useInView } from "react-intersection-observer";
import { SquareLoader } from "react-spinners";
import "video-react/dist/video-react.css";


function ProjectDetails() {
  const [image, setImage] = useState([]);
  const [isVideoPlaying, setIsVideoPlaying] = useState(
    Array(image.length).fill(false)
  );
  const videoRefs = useRef([]);

  const handleVideoClick = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement.paused) {
      videoElement.play();
      setIsVideoPlaying((prevState) => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
    } else {
      videoElement.pause();
      setIsVideoPlaying((prevState) => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    }
  };


  const { slugAndId } = useParams();
  const lastHyphenIndex = slugAndId.lastIndexOf("-");
  const productId = slugAndId.substring(lastHyphenIndex + 1);
  const [title, setTitle] = useState("");
  const [titletwo, setTitletwo] = useState("");
  const [titlethree, setTitlethree] = useState("");
  const [description, setDescription] = useState("");
  const [project, setProject] = useState(null);
  
  
  async function details() {
    const response = await axios.get(`${url.baseURL}/project/${productId}`);
    setTitle(response.data.project.name);
    setTitletwo(response.data.project.titletwo);
    setTitlethree(response.data.project.titlethree);
    setDescription(response.data.project.description);
    setImage(response.data.project.projectimgdetails);
    setProject(response.data.project);
    console.log(response.data.project.name);
  }

  const [_, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    details();
    console.clear();
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
      easing: "ease-in-out",
      delay: 200,
    });
  }, []);

  if (!project) {
    return (
      <div className="loading-spinner-center">
        <SquareLoader color="#27E646" loading={true} />
      </div>
    );
  }

  const handleLinkClick = (event) => {
    event.preventDefault();
    window.location.href = event.target.href;
  };











  return (
    <div>
      <div className="container mt-5">
        <ol className="breadcrumb">
          <li>
            <Link onClick={handleLinkClick} to="/projects">
              Projects
            </Link>
          </li>
          <li>
            <Link className="active">{title}</Link>
          </li>
        </ol>
      </div>

      <section className="project-details pb-5">
        <div className="container">
          <div className="row-project">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="col-description"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <h2>{title}</h2>
                  <h4>{titletwo}</h4>
                  <p>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </p>
                  <p>
                    <div dangerouslySetInnerHTML={{ __html: titlethree }} />
                  </p>
                </div>
              </div>

              <div className="col-md-6">
                {image.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className="col-image mb-4"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      {item ? (
                        item.type === "image" ? (
                          <img
                            src={`${url.mediaURL}/${item.image}`}
                            alt=""
                            className="img-fluid"
                            title=""
                          />
                        ) : (
                          <div>
                            <video
                              id="my-video"
                              preload="metadata"
                              controls={false}
                              ref={(el) => (videoRefs.current[index] = el)}
                              loop
                              playsInline
                              onPause={() => {
                                setIsVideoPlaying((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = false;
                                  return newState;
                                });
                              }}
                              onPlay={() => {
                                setIsVideoPlaying((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = true;
                                  return newState;
                                });
                              }}
                              style={{ opacity: inView ? 1 : 1 }}
                              onClick={() => handleVideoClick(index)}
                            >
                              <source
                                src={`${url.mediaURL}/${item.image}`}
                                type="video/mp4"
                              />
                            </video>
                            {!isVideoPlaying[index] && (
                              <img
                                src={playBtn}
                                alt="Play Button"
                                className="play-button"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleVideoClick(index)}
                              />
                            )}
                          </div>
                        )
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <GoToTop />
    </div>
  );
}

export default ProjectDetails;