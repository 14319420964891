
import React, {useState, useEffect ,  useRef } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios";
import url from "../url.jsx"
import './OurBlogs.css'
function OurBlogs() {
    const[blog,setBlog]=useState([]);
async function getblog()
{

    const response = await axios.get(`${url.baseURL}/blog`);
    setBlog(response.data.blog);
    
}
const handleLinkClick = (event) => {
  event.preventDefault();
  window.location.href = event.target.href;
};

useEffect(() => {

getblog();
console.clear();
}, [])

    const about = useRef(null);

    const scrollToSection = (elementRef) => {
      window.scrollTo({
        top: elementRef.current.offsetTop,
        behavior: "smooth",
      });
    };


  return (
    <div>
    <section className="case-studies mt-50">
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <div className="color-break">
<h2> OUR
  <br/><strong className="color-green">BLOGS </strong></h2>
</div>

<div className="scroll-section">
<Link className="scroll-style" onClick={()=> scrollToSection(about)}>
<img src="assets/img/goimage/square.svg" className="img-scroll-square" />
<span className="vertical-text">
Scroll to explore
</span>
<img className="imgscroll2" src="assets/img/goimage/arrow-down.svg" />
</Link>
</div>
            </div>

            <div className="col-lg-6">
                <div className="case-description">
                <p  className="h3-recent">This is where we share our unfiltered thoughts on literally anything.
                You never really know what you're going to get.</p>

                </div>
            </div>
        </div>
    </div>
</section>


     
        <div className="container pb-5">
        <div className="row">
        {blog.map((item)=>{
        return[
            <div  className="col-md-6">
            <div class="case-border">
      
            {item.blog_images.length > 0 ? (
              item.blog_images[0].type === 'image' ? (
            <a href={"/blogs-details/"+ item.slug + "-" + item.id}>
                  <div className="image-containerr">
                    <img
                      alt={item.title}
                      className="img-fluid"
                      title=""
                      src={`${url.mediaURL}/${item.blog_images[0].image}`}
                    />
                    <div className="image-overlay">
                    <div class="p-overlay d-block">
                    <p className="text-blog-by-title mb-md-3 mb-2">{item.title}</p>
                    <p class="text-blog-by">{item.by}</p>
                    <p class="text-blog-by">{item.date}</p>
                     </div>
                    </div>
                    </div>
                  </a>
                )  : (
                
                      <div className="video-wrapper">
                        <video
                          style={{
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          playsInline
                          loop
                          autoPlay
                          muted
                          controls
                          alt={item.name}
                          poster={`${url.mediaURL}/${item.blog_images[0].image}`}
                        />
                      </div>
               
         ) ) : null}
    
         
                </div>
            </div>
    
        ]
    })}
    </div>
    </div>
    

     
    </div>
  )
}

export default OurBlogs;