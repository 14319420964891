import React, { useState , useEffect} from 'react';
import { Link , useParams } from 'react-router-dom';
import './JobsList.css'; 
import AOS from 'aos';
import axios from "axios";
import url from "../url.jsx";
import { SquareLoader } from 'react-spinners';
const JobsList = () => {
  const params=useParams();
  const [showLoader, setShowLoader] = useState(true);
const [position , setPosition] = useState([]);

  async function getposition() {
    const response = await axios.get(`${url.baseURL}/position`);
    setPosition(response.data.joblist);
    console.log(response.data.joblist);
  }
useEffect(() => {
  getposition();
}, [])

const handleLinkClick = (event) => {
  event.preventDefault();
  window.location.href = event.target.href;
};

  return (
    <div>
    <div className="jobs-list-container">
    <div className="container">
   
      <div>
        {position.length > 0 ? (
          <div>
          <h5 className="text-white pt-5">We’re on the Hunt for Innovative Minds.</h5>
          <p className="text-opacity">Check out the open positions below.</p>
          <table className="centered-table table-responsive">
            <thead>
              <tr>
                <th className="fw-bold fs-6 position">Position</th>
                <th className="fw-bold fs-6 office">Office</th>
                <th className="fw-bold fs-6 status">Status</th>
                <th className="fw-bold fs-6 go-to-app">Go To Application</th>
              </tr>
            </thead>
            <tbody>
              {position.map((item) => (
                <tr key={item.id}>
                  <td class="no-overlap">
                    <Link  onClick={handleLinkClick} to={"/job-Details/" + item.slug + "-" + item.id}>{item.position}</Link>
                  </td>
                  <td>{item.office}</td>
                  <td>{item.status ? "Expired" : "Active"}</td>
                  <td>
                    {!item.status && (
                      <Link className="btn-apply pt-2 pb-2"  onClick={handleLinkClick} to={"/job/" + item.slug + "-" + item.id}>
                        Apply
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        ) : (
          <div class="list-data-img">
          
          <h5 className="text-white pt-5 text-capitalize">We’re all staffed up at the moment. </h5>
          <p className="text-opacity">Keep an eye out because who knows what’s around the corner.</p>
          <div class="nodata">
          <img src="assets/img/goimage/nodata.png" class="img-data"/>
          </div>
</div>
        )}
      </div>
    </div>
  </div>



    <section className="infinite-text-about">
    <div className="marquee">
      <div className="track">
        <div className="data-driven-infinite text-uppercase">&nbsp;Success is best when it’s shared. Success is best when it’s shared. Success is best when it’s shared. Success is best when it’s shared. Success is best when it’s shared.</div>
      </div>
    </div>
  </section>
  </div>

  );
};

export default JobsList;
